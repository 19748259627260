<template>
  <div class="point-generate">
    <div class="container" v-if="$store.state.account.loggedIn">
      <i class="fa fa-money" aria-hidden="true"></i>
      <div class="pt-3">
        <b class="font-md">포인트 쿠폰 발급 페이지</b>
      </div>
      <div class="pt-3">
        <a @click="redirect()" class="color-anchor pointer-u font-sm">{{ prevPage ? "이전페이지" : "홈으로" }}</a>
        <router-link to="/mypage" class="color-anchor pointer-u font-sm">마이페이지로</router-link>
      </div>
    </div>
    <Loading :opaque="true" v-else/>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import router from "@/scripts/router";
import {httpError} from "@/scripts/httpError";
import store from "@/scripts/store";
import storage from "@/scripts/storage";
import Loading from "@/components/Loading.vue";

function Component(initialize) {
  this.name = "pagePointGenerate";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Loading},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      const couponId = router.app.$route.params.couponId;

      const move = (message) => {
        store.commit("confirm", {
          message,
          allowTxt: "마이페이지로 이동",
          disallowTxt: prevPage ? "이전페이지" : "홈으로",
          allow() {
            router.replace({path: "/mypage/coupon"});
          },
          disallow() {
            redirect();
          }
        });
      };

      http.post(`/api/coupons/${couponId}`, undefined, {message: false}).then(({data}) => {
        move(data.message);
      }).catch(httpError((err) => {
        err.response.data?.code !== "LOGIN_REQUIRED" && move(err.response.data.message);
      }));
    });

    const prevPage = storage.get("session", "redirectPath");

    const redirect = () => {
      const redirectUrl = prevPage || "/";
      return router.replace(redirectUrl);
    };

    const moveBack = () => {
      window.history.back();
    };

    return {component, prevPage, moveBack, redirect};
  }
});
</script>

<style lang="scss" scoped>
.point-generate {

  > .container {
    padding-top: $px100;
    padding-bottom: $px110;
    text-align: center;

    > i {
      font-size: $px70;
    }

    > div > a {
      margin: 0 $px5;
    }
  }
}
</style>